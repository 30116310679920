import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

// px2rem 自适应
import 'lib-flexible'

Vue.prototype.$https = axios
axios.defaults.baseURL = 'https://62a4b9ae.r20.cpolar.top/'


import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
