<template>
    <div>
        <div class="header">
            <h3 v-html="title"></h3>
        </div>
        <div class="money">
            <span>订单金额：</span>
            <input v-model="pay_money" class="input-money" readonly="readonly" type="text" autofocus
                pattern="^\d*(\.\d{0,2})?$">
            <span>元</span>
        </div>
        <!-- 优惠券单元格 -->
        <div class="yhq">
            <div>优惠券</div>
            <div @click="showPopup">
                <span :class="money == '' ? '' : 'active'">
                    {{ money == '' ? '暂无可用' : '-￥' + money }}
                </span>
                <van-icon name="arrow" />
            </div>
        </div>
        <!-- 积分单元格 -->
        <div class="yhq">
            <div>积分</div>
            <div @click="showScorePopup">
                <span :class="score == '' ? '' : 'active'">
                    {{ score == '' ? '暂无可用' : '-￥' + (score / 1000).toFixed(2) }}
                </span>
                <van-icon name="arrow" />
            </div>
        </div>
        <div class="key_box">
            <div class="key_box_left">
                <button class="num" data-index="1" @click="getKeyNumber">1</button>
                <button class="num" data-index="2" @click="getKeyNumber">2</button>
                <button class="num" data-index="3" @click="getKeyNumber">3</button>
                <button class="num" data-index="4" @click="getKeyNumber">4</button>
                <button class="num" data-index="5" @click="getKeyNumber">5</button>
                <button class="num" data-index="6" @click="getKeyNumber">6</button>
                <button class="num" data-index="7" @click="getKeyNumber">7</button>
                <button class="num" data-index="8" @click="getKeyNumber">8</button>
                <button class="num" data-index="9" @click="getKeyNumber">9</button>
                <button class="num" data-index="0" @click="getKeyNumber">0</button>
                <button class="num" data-index="." @click="getKeyNumber">.</button>
            </div>
            <div class="key_box_right">
                <div class="num" data-index="" @click="delNum">✖</div>
                <div class="num" data-index="cleer" @click="cleerNumber">清空</div>
                <div @click="submit">支付</div>
            </div>
        </div>
        <!-- 优惠券 -->
        <div class="popup">
            <van-popup v-model="show" position="bottom" round
                style="height: 50%;padding: .2667rem; box-sizing: border-box; background-color: #f7f8fa;">
                <van-radio-group v-model="result" :max="1" icon-size="40" checked-color="#31b158" @change="onChange">
                    <div class="item_content">
                        <div class="item">
                            <div class="item_top">
                                <div>
                                    <span>1.50</span>
                                    元
                                </div>
                                <div>
                                    <div class="yhq_name">工商银行代金券</div>
                                    <div class="yhq_time">2024.03.10 - 2024.12.30</div>
                                </div>
                                <div>
                                    <van-radio name="a"></van-radio>
                                </div>
                            </div>
                            <div>消费满10可用</div>
                        </div>
                        <div class="item">
                            <div class="item_top">
                                <div>
                                    <span>2.50</span>
                                    元
                                </div>
                                <div>
                                    <div class="yhq_name">农行银行代金券</div>
                                    <div class="yhq_time">2024.03.10 - 2024.12.30</div>
                                </div>
                                <div>
                                    <van-radio name="b"></van-radio>
                                </div>
                            </div>
                            <div>消费满40可用</div>
                        </div>
                        <div class="item">
                            <div class="item_top">
                                <div>
                                    <span>5.00</span>
                                    元
                                </div>
                                <div>
                                    <div class="yhq_name">农行信用卡代金券</div>
                                    <div class="yhq_time">2024.03.10 - 2024.12.30</div>
                                </div>
                                <div>
                                    <van-radio name="c"></van-radio>
                                </div>
                            </div>
                            <div>消费满100可用</div>
                        </div>
                    </div>
                    <div class="btn" @click="closePopup">不使用代金券</div>
                </van-radio-group>
            </van-popup>
        </div>
        <!-- 积分 -->
        <div class="popup">
            <van-popup v-model="scoreShow" position="bottom" round
                style="height: 30%;padding: .2667rem; box-sizing: border-box; background-color: #f7f8fa;">
                <div class="score_content">
                    <div>
                        <div>总积分</div>
                        <div>2000</div>
                    </div>
                    <div>
                        <div>本次消耗</div>
                        <div>
                            <input type="number" v-model="score">
                        </div>
                    </div>
                    <div>
                        <div></div>
                        <div class="text">数据来源于: 菜商云会员端</div>
                    </div>
                </div>
                <div class="btn" @click="closeScorePopup">不使用积分</div>
            </van-popup>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            title: "菜商云88号小徐 水果摊",
            pay_money: "",
            statefocus: true,
            num: [],
            show: false,
            result: '',
            money: "",
            score: "",
            scoreShow: false,
            market_id: "",
            qrid: "",
            open_id: "",
            radio: "微信支付",
            action: "", //当前是哪一个浏览器
            channel_type: "", //给后端判断当前是什么支付的
        }
    },
    created() {
        let urlaction = this.browserJudgment();
        if (urlaction == 1) {
            this.action = urlaction;
            this.channel_type = 2;
            this.radio = "微信支付";
        } else if (urlaction == 2) {
            this.channel_type = 1;
            this.action = urlaction;
            this.radio = "支付宝支付";
        } else if (urlaction == 3) {
            this.action = urlaction;
            this.channel_type = 3;
        }
    },
    mounted() {
        this.getTitle();
        this.market_id = this.$route.query.market_id;
        this.qrid = this.$route.query.qrid;
        this.open_id = this.$route.query.open_id;
        this.getOpenId()
    },
    methods: {
        getOpenId() {
            const code = this.$route.query.code  // 截取路径中的code,
            if (code == null || code === '' || code === false) {
                const local = window.location.href
                let appid = 'wxd48f4e15e03e8bc3'
                window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_base&state=1#wechat_redirect';
            } else {
                let that = this;
                axios.post('data/test/get-openid?code=' + code).then((res) => {
                    console.log(res.data)
                })
            }
        },

        //判读当前页面是什么跳转过来的
        browserJudgment() {
            var ua = window.navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                console.log("微信端");
                return "1";
            } else if (ua.match(/Alipay/i) == "alipay") {
                console.log("支付宝端");
                return "2";
            } else {
                console.log("其他浏览器");
                return "3";
            }
        },
        showPopup() {
            this.show = true;
        },
        closePopup() {
            this.result = '';
            this.money = '';
            this.show = false;
        },
        showScorePopup() {
            this.scoreShow = true
        },
        closeScorePopup() {
            this.score = '';
            this.scoreShow = false
        },
        onChange(e) {
            if (this.result == 'a') {
                this.money = '1.50'
            }
            if (this.result == 'b') {
                this.money = '2.50'
            }
            if (this.result == 'c') {
                this.money = '5.00'
            }
            this.show = false;
        },
        getTitle() {
            var str = this.title;
            this.title = str.replace(/\s+/g, "</br>");
        },
        // 数字键盘
        getKeyUp(key) {
            switch (key) {
                case "0":
                case "1":
                case "2":
                case "3":
                case "4":
                case "5":
                case "6":
                case "7":
                case "8":
                case "9":
                case ".":
                    this.getKeyNumber({ value: key });
                    break;
                case "CLEAN":
                    this.delNum();
                    break;
                default:
                    break;
            }
            return key;
        },
        // 数字键盘
        getKeyNumber(e) {
            // 如果之前就是一个 0
            if (this.pay_money === "0") {
                // 如果输入0,排除00的情况
                if (e.target.innerText === "0") {
                    return false;
                } else if (e.target.innerText !== ".") {
                    this.pay_money = "";
                }
            }
            // 如果输入的是'.'，
            if (e.target.innerText === ".") {
                // 如果没有输入'.'之前的数据是空的话需要补零
                if (this.pay_money === "") {
                    this.pay_money = "0";
                }
                if (this.pay_money.indexOf(".") > -1) {
                    // 需要验证之前有没有输入'.',有的话就不在叠加 '.',
                    return false;
                }
            }
            // 判断输入的小数位数
            if (this.pay_money.indexOf(".") > -1) {
                // 如果输入了小数点，只能输入两位小数
                let num = this.pay_money.split(".");
                if (num[1].length >= 2) {
                    return false;
                }
            } else {
                // 如果还没输入小数,然后还在不停的输入数字
                if (e.target.innerText !== "." && this.pay_money.length > 3) {
                    return false;
                }
            }
            this.pay_money += String(e.target.innerText);
        },
        // 数字键盘 删除
        delNum(e) {
            if (this.pay_money.length == 0) {
                return false;
            }
            this.pay_money = this.pay_money.substring(0, this.pay_money.length - 1);
        },
        cleerNumber() {
            this.pay_money = "";
            this.num = [];
        },

        submit() {
            this.pay_money = "";
            this.num = [];
        },


    },

}
</script>

<style lang="less" scoped>
.header {
    width: 100vw;
    line-height: 1.3333rem;
    color: #000;
    margin-top: 2.1333rem;
    text-align: center;
    font-size: .64rem;
    padding: 0 .5333rem;
    box-sizing: border-box;
    margin-bottom: .8rem;
}

.money {
    display: flex;
    padding: 0 .5333rem;
    box-sizing: border-box;
    height: 1.2rem;
    line-height: 1.2rem;
    margin-bottom: .8rem;
}

.money span {
    color: #000;
    font-size: .6rem;
    font-weight: 600;
    height: 1.2rem;
    line-height: 1.2rem;
}

.money input {
    color: #000;
    font-size: .4267rem;
    font-weight: 600;
    border: none;
    background-color: #f9f9f9;
    width: 5rem;
    box-sizing: border-box;
    height: 1.2rem;
    line-height: 1.2rem;
    margin-right: .2133rem;
    outline: none;
    border-radius: .1333rem;
    font-size: 1rem;
    text-align: right;
    padding: 0 .4267rem;
    overflow: hidden;
}

.key_box {
    width: 100vw;
    height: 7.5rem;
    background-color: #dddddd;
    position: fixed;
    bottom: 0;
    padding: .16rem .16rem;
    box-sizing: border-box;
    color: #000;
    font-weight: 600;
}

.key_box_left {
    float: left;
    width: 78%;
    height: 100%;
}

.key_box .key_box_left>button {
    display: flex;
    float: left;
    justify-content: center;
    align-items: center;
    width: 2.4rem;
    margin-right: .1rem;
    margin-bottom: .1rem;
    height: 1.7rem;
    background-color: #fff;
    border-radius: .1333rem;
    font-size: 1rem;
    font-weight: 600;
    border: none;
}

.num:active {
    background-color: #ccc !important;
}

.key_box .key_box_left>button:nth-child(10) {
    width: 4.9rem;
}

.key_box_right {
    float: right;
    width: 22%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: .64rem;
}

.key_box_right>div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .1333rem;
}

.key_box_right>div:nth-child(1) {
    width: 100%;
    height: 1.7rem;
    background-color: #fff;
    margin-bottom: .1rem;
}

.key_box_right>div:nth-child(2) {
    width: 100%;
    height: 1.7rem;
    background-color: #fff;
    margin-bottom: .1rem;
    font-size: .8533rem;
}

.key_box_right>div:nth-child(3) {
    width: 100%;
    height: 3.49rem;
    background-color: #05c160;
    color: #fff;
    font-size: .8533rem;
}

.yhq {
    width: 100%;
    height: 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 .5333rem;
    font-size: .4267rem;
    // margin-top: .8rem;
}



.yhq>div:nth-child(1) {
    color: #323233;
}

.yhq>div:nth-child(2) {
    color: #969799;
    font-size: .3733rem;
}

.item {
    width: 100%;
    height: 2.6667rem;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: .2133rem;
    margin-bottom: .2667rem;
}

.item_content {
    width: 100%;
    height: 6.9333rem;
    overflow-y: auto;
}

.item>div:nth-child(1) {
    width: 100%;
    height: 75%;
    box-sizing: border-box;
    padding-bottom: .1067rem;
    border-bottom: .0267rem dashed #ebedf0;
}

.item>div:nth-child(2) {
    width: 100%;
    height: 25%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: .32rem;
    color: #323233;
    padding: 0 .4rem;
}

.item_top {
    display: flex;
}

.item_top>div:nth-child(1) {
    flex: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ee0a24;
    box-sizing: border-box;
    padding-top: .2667rem;
}

.item_top>div:nth-child(1) span {
    font-size: .8rem;
    margin-top: -.24rem;
    margin-right: .16rem;
}

.item_top>div:nth-child(2) {
    flex: 4;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.item_top>div:nth-child(3) {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.yhq_name {
    color: #323233;
    margin-bottom: .2667rem;
    font-weight: bold;
    font-size: .3733rem;
    line-height: .5333rem;
}

.yhq_time {
    font-size: .32rem;
    color: #323233;
}

.btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.3333rem;
    background-color: #ee0a24;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .42rem;
    color: #fff;
    font-weight: 600;
}

.active {
    color: #ee0a24 !important;
}

.score_content {
    width: 100%;
    height: 3.2rem;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    >div {
        flex: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: .48rem;
        border-bottom: .0267rem dashed #ebedf0;
        box-sizing: border-box;
        padding: 0 .2133rem;
    }

    >div>div:nth-child(2) {
        color: #31b158;
        font-weight: 600;
        font-size: .5333rem;
    }

    input {
        height: 1.0667rem;
        outline: none;
        width: 3.2rem;
        border: none;
        text-align: right;
        color: #ee0a24 !important;
    }

    .text {
        display: flex !important;
        justify-content: flex-end !important;
        align-items: flex-end !important;
        font-size: .3733rem !important;
        color: #323233 !important;
        font-weight: normal !important;
    }
}
</style>